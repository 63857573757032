
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RoleCode, RunStatus } from '@/types';
import { mdiCheckCircleOutline, mdiExclamationThick, mdiClockFast, mdiEyeCheckOutline } from '@mdi/js';
import store from '@/store';
import { Getter } from 'vuex-class';

@Component
export default class RunStatusChip extends Vue {
	@Prop({ required: true }) private status!: RunStatus;
	@Prop({ required: true }) private docId!: string;
	@Prop({ required: true }) private collection!: string;
	@Prop(Boolean) private isSmall!: boolean;
	@Prop(Boolean) private isLabel!: boolean;

	@Getter('user/role') userRole!: RoleCode;

	showMenu: boolean = false;
	showSnackbar: boolean = false;
	isLoading: boolean = false;

	updateStatus(value: string) {
		if (value === this.status) return;

		this.showSnackbar = false;
		this.isLoading = true;

		store.dispatch(`${this.collection}/patch`, { id: this.docId, status: value }).then(() => {
			this.isLoading = false;
			this.showSnackbar = true;
		});
	}

	get color() {
		if (this.isLoading) return 'blue-grey';

		let statusColor;
		switch (this.status) {
			case 'SUCCESS':
				statusColor = 'green';
				break;
			case 'FAILED':
				statusColor = 'red';
				break;
			case 'RUNNING':
				statusColor = 'light-blue';
				break;
			case 'CHECKED':
				statusColor = 'indigo';
				break;
			default:
				statusColor = 'orange';
				break;
		}
		return statusColor;
	}

	get runStatusList() {
		const runStatusList: { icon: string; value: RunStatus }[] = [
			{
				icon: mdiCheckCircleOutline,
				value: 'SUCCESS',
			},
			{
				icon: mdiExclamationThick,
				value: 'FAILED',
			},
			{
				icon: mdiClockFast,
				value: 'RUNNING',
			},
			{
				icon: mdiEyeCheckOutline,
				value: 'CHECKED',
			},
		];

		return runStatusList;
	}
}
