var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.directExecution
        ? _c("v-icon", { attrs: { color: "success" } }, [_vm._v("mdi-rocket")])
        : _c("v-icon", { attrs: { color: "error" } }, [_vm._v("mdi-tortoise")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }