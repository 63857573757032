var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-chip",
        {
          class: {
            "text-uppercase": _vm.isLabel,
            "text-capitalize": !_vm.isLabel,
            "no-opacity": this.item.archived,
          },
          attrs: {
            color: _vm.color,
            "text-color": "white",
            small: _vm.isSmall,
            label: _vm.isLabel,
            disabled: _vm.item.archived,
          },
          on: {
            click: function ($event) {
              !_vm.isViewer &&
                _vm.changeActivatedStatus(_vm.item, _vm.collection)
            },
          },
        },
        [
          _vm.isLoading
            ? _c("v-progress-circular", {
                attrs: { indeterminate: "", size: "20", color: "primary" },
              })
            : _c("span", [_vm._v(_vm._s(_vm.label))]),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { color: _vm.snackbarParam.color, timeout: _vm.timeout },
          model: {
            value: _vm.snackbarParam.isVisible,
            callback: function ($$v) {
              _vm.$set(_vm.snackbarParam, "isVisible", $$v)
            },
            expression: "snackbarParam.isVisible",
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.snackbarParam.text) + " "),
          _c(
            "v-btn",
            { attrs: { text: "" }, on: { click: _vm.closeSnackbar } },
            [_vm._v("Close")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }