
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ConfActivatedFilter, DateFilter, EnvFilter, FilterState, RunStatusFilter } from '@/types';
import { Getter, State } from 'vuex-class';
import { CONFIGURATIONS, EXPECTATIONS, FRESHNESS, RUNS } from '@/constants/data-operations/status';
import { TaskCriticalities } from '@/constants/data-quality/filters';

type Filter = {
	values: { label: string; value: string | number | boolean }[];
	selectedValue: { label: string; value: string | number | boolean };
	clickAction: Function;
};

@Component
export default class DataManagementFilters extends Vue {
	@Prop({ required: true }) type!: any;

	@State((state) => state.filters.envFilterSelected) envFilterSelected!: EnvFilter;
	@State((state) => state.filters.envFilters) envFilters!: EnvFilter[];
	@State((state) => state.filters.runStatusFilterSelected) runStatusFilterSelected!: RunStatusFilter;
	@State((state) => state.filters.runStatusFilters) runStatusFilters!: RunStatusFilter[];
	@State((state) => state.filters.confActivatedFilterSelected) confActivatedFilterSelected!: ConfActivatedFilter;
	@State((state) => state.filters.confActivatedFilters) confActivatedFilters!: ConfActivatedFilter[];
	@State((state) => state.filters.dateFilterSelected) dateFilterSelected!: DateFilter;
	@State((state) => state.filters.dateFilters) dateFilters!: DateFilter[];
	@State((state) => state.filters.minDateFilter) minDateFilter!: string;
	@State((state) => state.filters.expectations) expectations!: FilterState['expectations'];

	@Getter('user/isSuperAdmin') isSuperAdmin!: number;

	created() {
		if (
			!this.$moment()
				.utc()
				.startOf('day')
				.subtract(this.dateFilterSelected.value, 'day')
				.isSame(this.$moment(this.minDateFilter))
		) {
			this.$store.commit('filters/updateMinDateFilter', this.dateFilterSelected);
		}
	}

	// TODO: Refactoring
	mounted() {
		const hasArchivedOption = this.confActivatedFilters.find((element) => String(element.label) === 'Archived');

		if (this.isSuperAdmin && !hasArchivedOption) {
			this.confActivatedFilters.push({ label: 'Archived', value: true });
		}
	}

	applyEnvFilter(envFilterSelected: EnvFilter): void {
		this.$store.dispatch('filters/applyEnvFilterSelected', envFilterSelected);
	}

	applyRunStatusFilter(runStatusSelected: RunStatusFilter): void {
		this.$store.dispatch('filters/applyRunStatusFilterSelected', runStatusSelected);
	}

	applyConfActivatedFilter(confActivatedSelected: ConfActivatedFilter): void {
		this.$store.dispatch('filters/applyConfActivatedFilterSelected', confActivatedSelected);
	}

	applyDateFilter(dateFilterSelected: DateFilter): void {
		this.$store.dispatch('filters/applyDateFilterSelected', dateFilterSelected);
	}

	applyTaskCriticality(taskCriticality: TaskCriticalities) {
		this.$store.dispatch('filters/applyTaskCriticality', taskCriticality);
	}

	applyPassed(passed: boolean) {
		this.$store.dispatch('filters/applyPassed', passed);
	}

	get filters() {
		let filters: Filter[] = [];

		if (this.type !== FRESHNESS)
			filters.push({
				values: this.envFilters,
				selectedValue: this.envFilterSelected,
				clickAction: this.applyEnvFilter,
			});

		if (this.type === RUNS)
			filters.push({
				values: this.runStatusFilters,
				selectedValue: this.runStatusFilterSelected,
				clickAction: this.applyRunStatusFilter,
			});

		if (this.type === CONFIGURATIONS)
			filters.push({
				values: this.confActivatedFilters,
				selectedValue: this.confActivatedFilterSelected,
				clickAction: this.applyConfActivatedFilter,
			});

		if (this.type === EXPECTATIONS)
			filters.push(
				{
					values: this.expectations.taskCriticalityOptions,
					selectedValue: this.expectations.taskCriticality,
					clickAction: this.applyTaskCriticality,
				},
				{
					values: this.expectations.passedOptions,
					selectedValue: this.expectations.passed,
					clickAction: this.applyPassed,
				}
			);

		if (this.type !== CONFIGURATIONS && this.type !== FRESHNESS)
			filters.push({
				values: this.dateFilters,
				selectedValue: this.dateFilterSelected,
				clickAction: this.applyDateFilter,
			});

		return filters;
	}
}
