import moment from 'moment';
import { Component, Vue } from 'vue-property-decorator';
import DataManagementHeader from '@/components/data-operations/common/DataManagementHeader.vue';
import ListingComponent from '@/components/data-operations/common/listing/ListingComponent.vue';

@Component({
	components: { DataManagementHeader, ListingComponent },
})
export default class CollectionMixin extends Vue {
	getFormattedDuration(duration: string | number) {
		const durationFormat = 'h[h]mm[m]ss[s]';

		if (typeof duration === 'string') {
			return moment.duration(duration).format(durationFormat);
		} else {
			return moment.duration(duration, 'seconds').format(durationFormat);
		}
	}
}
