
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import merge from 'lodash.merge';
import moment from 'moment';

import ConfigurationStatus from '../configuration/ConfigurationStatus.vue';
import ListingFilters from './ListingFilters.vue';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import RunStatusChip from '@/components/data-operations/common/runs/RunStatusChip.vue';
import DirectExecutionIcon from '@/components/data-operations/common/item/parameters/custom-parameters-item/DirectExecutionIcon.vue';

import { AnyObject, RoleCode, HeaderItem } from '@/types';
import { CONFIGURATIONS, EXPECTATIONS, RUNS, STATUS } from '@/constants/data-operations/status';
import { getActiveConfColor } from '@/util/data-operations/configuration';
import { mapState } from 'vuex';

type Filter = [string, string, any];

@Component({
	components: { ConfigurationStatus, DirectExecutionIcon, ListingFilters, VueJsonPretty, RunStatusChip },
	computed: {
		...mapState({
			firestoreItems(state: any) {
				return state[this.dynamicModuleName].data;
			},
		}),
	},
})
export default class ListingComponent extends Vue {
	@Prop({ type: String, required: true }) type!: string; // TODO: Use RUNS or CONFIGURATIONS constants
	@Prop({ type: String, required: true }) moduleName!: string;
	@Prop({ type: String, required: false }) moduleNameLight?: string;
	@Prop({ type: Array, required: true }) headers!: HeaderItem[];
	@Prop({ type: String, default: 'dag_execution_date' }) sortBy?: string[];
	@Prop({ type: Boolean, default: true }) sortDesc?: boolean;
	@Prop({ type: String }) directExecutionPath?: string;
	@Prop({ type: Boolean, default: false }) showDeleteAction?: boolean;
	@Prop(Function) customDataFetching?: () => Promise<any>;
	@Prop(Array) customFiltersValues?: (string | number)[];
	@Prop(Boolean) isOtherRunDisplay?: boolean;
	@Prop(String) jobId?: string;
	@Prop(Array) overriddenColumns?: string[];

	@Getter('user/role') userRole!: RoleCode;
	@Getter('filters/periodFiltered') periodFiltered!: string[];
	@Getter('filters/whereStatusFilter') whereStatusFilter!: Filter[];
	@Getter('filters/whereRunsFilter') whereRunsFilter!: Filter[];
	@Getter('filters/whereConfFilter') whereConfFilter!: Filter[];
	@Getter('filters/whereExpectationsFilters') whereExpectationsFilters!: Filter[];

	@Watch('whereStatusFilter')
	onWhereStatusFilterChange() {
		this.getFirestoreData();
	}

	@Watch('whereRunsFilter')
	onWhereRunsFilterChange() {
		this.getFirestoreData();
	}

	@Watch('whereConfFilter')
	onWhereConfFilterChange() {
		this.getFirestoreData();
	}

	@Watch('whereExpectationsFilters')
	onWhereExpectationsFiltersChange() {
		this.getFirestoreData();
	}

	@Watch('customFiltersValues', { deep: true })
	onCustomFiltersValuesChanged() {
		this.getFirestoreData();
	}

	private firestoreItems: any;
	isLoading: boolean = false;
	search = this.$route.query.search ?? '';
	showDeleteDialog: boolean = false;
	showDeleteItemDetails: boolean = false;
	showSnackbarDeleteConfSuccess: boolean = false;
	itemToDelete: AnyObject = {};
	customFetchedItems: AnyObject[] = [];
	dynamicModuleName = this.moduleNameLight ? this.moduleNameLight : this.moduleName;

	mounted() {
		this.getFirestoreData();
	}

	// activated() {
	// 	// TODO: refetch without isLoading and removing all displayed data, and them when refetch is done
	// 	this.getFirestoreData();
	// }

	async beforeDestroy() {
		if (!this.customDataFetching) {
			// TODO: Reset value
			// _identifier is not existing to properly close channel (Discussed with Luca)
			await this.$store.dispatch(`${this.dynamicModuleName}/closeDBChannel`, {
				_identifier: 'none',
				clearModule: true,
			});
		}
	}

	getDirectExecution(item: any, path: string) {
		path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
		path = path.replace(/^\./, ''); // strip a leading dot
		const a = path.split('.');
		for (let i = 0, n = a.length; i < n; ++i) {
			if (typeof item === 'string') return;
			const k = a[i];
			if (k in item) {
				item = item[k];
			} else {
				return;
			}
		}
		return item;
	}

	openDeleteDialog(item: AnyObject) {
		this.itemToDelete = item;
		this.showDeleteDialog = true;
	}

	cancelDeleteConfFromFirestore() {
		this.showDeleteDialog = false;
		this.itemToDelete = {};
		this.showDeleteItemDetails = false;
	}

	confirmDeleteConfFromFirestore() {
		this.showDeleteDialog = false;
		this.showSnackbarDeleteConfSuccess = false;
		this.$store.dispatch(`${this.dynamicModuleName}/delete`, this.itemToDelete.id).then(() => {
			this.showSnackbarDeleteConfSuccess = true;
		});
		this.itemToDelete = {};
		this.showDeleteItemDetails = false;
	}

	async getFirestoreData() {
		let where: Filter[];
		let wherePerfAttribute: string = '';

		if (this.isOtherRunDisplay) {
			const minDate = moment().utc().startOf('day').subtract(1, 'month').toISOString();

			where = [
				['account', '==', JSON.parse(localStorage.vuex).filters.filteredAccounts[0].id],
				['dag_execution_date', '>=', minDate],
			];

			if (this.jobId) {
				where.push(['job_id', '==', this.jobId]);
			}
			wherePerfAttribute = 'otherRunDisplay';
		} else {
			switch (this.type) {
				case RUNS:
					where = this.whereRunsFilter;
					wherePerfAttribute = 'whereRunsFilter';
					break;
				case CONFIGURATIONS:
					where = this.whereConfFilter;
					wherePerfAttribute = 'whereConfFilter';
					break;
				case STATUS:
					where = this.whereStatusFilter;
					wherePerfAttribute = 'whereStatusFilter';
					break;
				case EXPECTATIONS:
					where = this.whereExpectationsFilters;
					wherePerfAttribute = 'whereExpectationsFilters';
					break;
				default:
					where = [];
					wherePerfAttribute = 'defaultFilters';
			}
		}

		/*const trace = this.$perf().trace('listing-trace');
		trace.putAttribute('moduleName', this.moduleName);
		trace.putAttribute('where', wherePerfAttribute);
		trace.start();*/

		this.isLoading = true;

		if (this.customDataFetching) {
			this.customFetchedItems = await this.customDataFetching();
			this.isLoading = false;
		} else {
			await this.$store.dispatch(`${this.dynamicModuleName}/closeDBChannel`, { clearModule: true });
			await this.$store.dispatch(`${this.dynamicModuleName}/openDBChannel`, { where, limit: 0 });
			this.isLoading = false;
		}

		// trace.stop();
	}

	// Archived
	isArchiveDialogVisible = false;
	confsToArchived: any[] = [];

	toggleConfsId(id: string, archived: boolean) {
		if (this.confsToArchived.some((conf) => conf.id === id)) {
			this.confsToArchived.splice(
				this.confsToArchived.findIndex((conf) => conf.id === id),
				1
			);
		} else {
			this.confsToArchived.push({
				id: id,
				arhived: archived,
			});
		}
	}

	archiveItem() {
		this.isLoading = true;
		this.confsToArchived.forEach((conf) => {
			const payload = conf.archived
				? { id: conf.id, archived: !conf.archived }
				: { id: conf.id, activated: false, archived: !conf.archived };
			this.$store.dispatch(`${this.moduleName}/patch`, payload);
		});
		this.isLoading = false;
		this.isArchiveDialogVisible = false;
		this.confsToArchived = [];
	}

	get footerProps() {
		return {
			itemsPerPageOptions: [10, 50, 100, 200, 500, -1],
		};
	}

	get itemsPerPage() {
		return 50;
	}

	get formattedItems() {
		const dataArray = Object.values(this.firestoreItems);
		const formattedData = dataArray.map(function (data: any) {
			return {
				activeConfColor: getActiveConfColor(data.activated, data.archived),
			};
		});
		return merge(dataArray, formattedData);
	}
}
