var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-tabs",
    {
      attrs: {
        slot: "extension",
        "slider-color": "activemenu",
        color: "activemenu",
        "background-color": "white",
      },
      slot: "extension",
    },
    _vm._l(_vm.tabsItems, function (tabsItem) {
      return _c(
        "v-tab",
        {
          key: tabsItem.id,
          attrs: {
            to: tabsItem.link,
            href: tabsItem.href,
            target: tabsItem.target,
          },
        },
        [
          _c("span", { staticClass: "pl-2 pr-2" }, [
            _vm._v(_vm._s(tabsItem.title)),
          ]),
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }