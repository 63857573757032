var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-toolbar",
        {
          staticClass: "elevation-O",
          attrs: { color: "transparent", flat: "" },
        },
        [
          _c("v-text-field", {
            attrs: {
              "append-icon": "search",
              label: "Search",
              "single-line": "",
              "hide-details": "",
            },
            model: {
              value: _vm.search,
              callback: function ($$v) {
                _vm.search = $$v
              },
              expression: "search",
            },
          }),
          _c("v-spacer"),
          !_vm.isOtherRunDisplay
            ? _c(
                "listing-filters",
                { attrs: { type: _vm.type } },
                [_vm._t("filters")],
                2
              )
            : _vm._e(),
          !_vm.isLoading
            ? _c(
                "v-icon",
                { attrs: { right: "" }, on: { click: _vm.getFirestoreData } },
                [_vm._v("refresh")]
              )
            : _vm._e(),
          _vm.isLoading
            ? _c("v-progress-circular", {
                attrs: { indeterminate: "", size: "20", color: "primary" },
              })
            : _vm._e(),
          _vm.confsToArchived.length
            ? [
                _c(
                  "v-btn",
                  {
                    staticClass: "mx-4",
                    attrs: { color: "primary" },
                    on: {
                      click: function ($event) {
                        _vm.isArchiveDialogVisible = !_vm.isArchiveDialogVisible
                      },
                    },
                  },
                  [_vm._v("Archive")]
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "v-data-table",
        {
          staticClass: "mb-16",
          attrs: {
            headers: _vm.headers,
            items: _vm.customDataFetching
              ? _vm.customFetchedItems
              : _vm.formattedItems,
            search: _vm.search,
            loading: _vm.isLoading,
            "sort-by": _vm.sortBy,
            "sort-desc": _vm.sortDesc,
            "item-key": "id",
            "footer-props": _vm.footerProps,
            "items-per-page": _vm.itemsPerPage,
          },
          on: {
            "update:sortBy": function ($event) {
              _vm.sortBy = $event
            },
            "update:sort-by": function ($event) {
              _vm.sortBy = $event
            },
            "update:sortDesc": function ($event) {
              _vm.sortDesc = $event
            },
            "update:sort-desc": function ($event) {
              _vm.sortDesc = $event
            },
          },
          scopedSlots: _vm._u(
            [
              {
                key: "item.activated",
                fn: function ({ item }) {
                  return [
                    _c("configuration-status", {
                      attrs: {
                        item: item,
                        collection: _vm.moduleName,
                        "is-activated": item.activated,
                      },
                    }),
                  ]
                },
              },
              {
                key: "item.status",
                fn: function ({ item: { id, status } }) {
                  return [
                    _c("run-status-chip", {
                      attrs: {
                        status: status,
                        "doc-id": id,
                        collection: _vm.moduleName,
                        "is-small": "",
                      },
                    }),
                  ]
                },
              },
              {
                key: "item.actions",
                fn: function ({ item }) {
                  return [
                    _c(
                      "div",
                      { staticClass: "d-flex" },
                      [
                        _vm.directExecutionPath
                          ? _c("direct-execution-icon", {
                              attrs: {
                                "direct-execution": _vm.getDirectExecution(
                                  item,
                                  _vm.directExecutionPath
                                ),
                              },
                            })
                          : _vm._e(),
                        _vm.showDeleteAction
                          ? _c(
                              "v-icon",
                              {
                                staticClass: "ml-2",
                                attrs: { small: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.openDeleteDialog(item)
                                  },
                                },
                              },
                              [_vm._v("delete_forever")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
              _vm._l(_vm.overriddenColumns, function (overriddenColumn) {
                return {
                  key: `item.${overriddenColumn}`,
                  fn: function ({ item }) {
                    return [_vm._t(overriddenColumn, null, null, { item })]
                  },
                }
              }),
              {
                key: "item.archived",
                fn: function ({ item: { id, archived } }) {
                  return [
                    _c("v-checkbox", {
                      staticClass: "mt-0 pb-1",
                      attrs: { "hide-details": "" },
                      on: {
                        click: function ($event) {
                          return _vm.toggleConfsId(id, archived)
                        },
                      },
                    }),
                  ]
                },
              },
            ],
            null,
            true
          ),
        },
        [
          _c("v-progress-linear", {
            attrs: { color: "blue", indeterminate: "" },
            scopedSlots: _vm._u([
              {
                key: "progress",
                fn: function () {
                  return undefined
                },
                proxy: true,
              },
            ]),
          }),
          _c("v-alert", {
            attrs: { value: true, color: "error", icon: "warning" },
            scopedSlots: _vm._u([
              {
                key: "no-results",
                fn: function () {
                  return [
                    _vm._v(
                      ' Your search for "' +
                        _vm._s(_vm.search) +
                        '" found no results. '
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "45%", "max-width": "700" },
          model: {
            value: _vm.showDeleteDialog,
            callback: function ($$v) {
              _vm.showDeleteDialog = $$v
            },
            expression: "showDeleteDialog",
          },
        },
        [
          _c(
            "v-card",
            { attrs: { light: "" } },
            [
              _c("v-card-title", { staticClass: "text-h5" }, [
                _vm._v("Delete Item"),
              ]),
              _c("v-card-text", [
                _vm._v(" Do you really want to delete this item? "),
                _c(
                  "h3",
                  { staticClass: "pt-3" },
                  [
                    _c("v-icon", { attrs: { size: "18" } }, [
                      _vm._v("arrow_forward"),
                    ]),
                    _vm._v(_vm._s(_vm.itemToDelete.id)),
                  ],
                  1
                ),
              ]),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function ($event) {
                          _vm.showDeleteItemDetails = !_vm.showDeleteItemDetails
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.showDeleteItemDetails
                              ? "Hide details"
                              : "Show more"
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "grey", text: "" },
                      on: { click: _vm.cancelDeleteConfFromFirestore },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error" },
                      on: { click: _vm.confirmDeleteConfFromFirestore },
                    },
                    [_vm._v("Delete")]
                  ),
                ],
                1
              ),
              _c(
                "v-slide-y-transition",
                [
                  _c(
                    "v-card-text",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showDeleteItemDetails,
                          expression: "showDeleteItemDetails",
                        },
                      ],
                    },
                    [
                      _c("vue-json-pretty", {
                        attrs: {
                          data: _vm.itemToDelete,
                          deep: 5,
                          "show-length": true,
                          "show-line": false,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { color: "success", timeout: 3500 },
          model: {
            value: _vm.showSnackbarDeleteConfSuccess,
            callback: function ($$v) {
              _vm.showSnackbarDeleteConfSuccess = $$v
            },
            expression: "showSnackbarDeleteConfSuccess",
          },
        },
        [_vm._v(" Configuration deleted with success! ")]
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "400" },
          model: {
            value: _vm.isArchiveDialogVisible,
            callback: function ($$v) {
              _vm.isArchiveDialogVisible = $$v
            },
            expression: "isArchiveDialogVisible",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h5 warning" }, [
                _vm._v("Warning"),
              ]),
              _c(
                "v-card-text",
                [
                  _c("p", { staticClass: "mt-4" }, [
                    _vm._v("Please confirm the archiving of"),
                  ]),
                  _vm._l(_vm.confsToArchived, function (item) {
                    return _c(
                      "p",
                      { key: item.id, staticClass: "font-weight-bold ma-0" },
                      [_vm._v(_vm._s(item.id))]
                    )
                  }),
                ],
                2
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.isArchiveDialogVisible = false
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                  _c(
                    "transition",
                    { attrs: { name: "fade", mode: "out-in" } },
                    [
                      _vm.isLoading
                        ? _c("v-progress-circular", {
                            attrs: {
                              indeterminate: "",
                              size: "20",
                              color: "primary",
                            },
                          })
                        : _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", text: "" },
                              on: { click: _vm.archiveItem },
                            },
                            [_vm._v("Archive")]
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }