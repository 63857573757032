var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          attrs: {
            bottom: "",
            right: "",
            transition: "scale-transition",
            origin: "top left",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    "v-chip",
                    _vm._g(
                      {
                        staticClass: "text-uppercase",
                        attrs: {
                          color: _vm.color,
                          "text-color": "white",
                          small: _vm.isSmall,
                          label: _vm.isLabel,
                        },
                      },
                      _vm.userRole >= 2 ? on : ""
                    ),
                    [
                      _vm.isLoading
                        ? _c("v-progress-circular", {
                            attrs: {
                              indeterminate: "",
                              size: "20",
                              color: "primary",
                            },
                          })
                        : _c("span", [_vm._v(_vm._s(_vm.status))]),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.showMenu,
            callback: function ($$v) {
              _vm.showMenu = $$v
            },
            expression: "showMenu",
          },
        },
        [
          _c(
            "v-card",
            { attrs: { width: "220" } },
            [
              _c(
                "v-list",
                { attrs: { dark: "" } },
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v("Update run status"),
                          ]),
                          _c("v-list-item-subtitle", [
                            _vm._v(_vm._s(_vm.status)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-action",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              on: {
                                click: function ($event) {
                                  _vm.showMenu = false
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v("mdi-close-circle")])],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-list",
                _vm._l(_vm.runStatusList, function (runStatus) {
                  return _c(
                    "v-list-item",
                    {
                      key: runStatus.value,
                      on: {
                        click: function ($event) {
                          return _vm.updateStatus(runStatus.value)
                        },
                      },
                    },
                    [
                      _c(
                        "v-list-item-action",
                        [_c("v-icon", [_vm._v(_vm._s(runStatus.icon))])],
                        1
                      ),
                      _c("v-list-item-subtitle", [
                        _vm._v(_vm._s(runStatus.value)),
                      ]),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { color: "info", timeout: 5000 },
          model: {
            value: _vm.showSnackbar,
            callback: function ($$v) {
              _vm.showSnackbar = $$v
            },
            expression: "showSnackbar",
          },
        },
        [
          _vm._v(" Run status updated successfully "),
          _c(
            "v-btn",
            {
              attrs: { text: "" },
              on: {
                click: function ($event) {
                  _vm.showSnackbar = false
                },
              },
            },
            [_vm._v("Close")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }